import GoogleMapReact from 'google-map-react';
import React, { Component } from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Map extends Component {
  static defaultProps = {
    center: {
      lat: 37.0003312,
      lng: -1.9124083
    },
    zoom: 14
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '20vh', width: '100%' }}>
        <GoogleMapReact
          // bootstrapURLKeys={{ key: 'AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg&v=weekly' }}
          bootstrapURLKeys={{ key: 'AIzaSyAQ2RLCUBF96BeXAWMaZTZSubTGhaMyDjQ' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          {/* <AnyReactComponent
            lat={37.0003312}
            lng={-1.9124083}
            text="My Marker"
          /> */}
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;
