import React from 'react';
import '../assets/styles/style.css'
import Map from './Map'
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Encuesta from './Encuesta';
import Reserva from './Reserva';
import axios from 'axios';
import { useTranslation } from 'react-i18next';


function BeachInfo({ location }) {

    const params = useParams();

    const api = `https://api.carboneras.notacool.com/api/v1/gestionPlayas/playa/${params.codnumplaya}`
    const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJVc2VyIERldGFpbHMiLCJpc3MiOiJZT1VSIEFQUExJQ0FUSU9OL1BST0pFQ1QvQ09NUEFOWSBOQU1FIiwiaWF0IjoxNjUzNDAwODcyLCJlbWFpbCI6ImJyYWlzIn0.rTqE0QHdDh2FHjyd1e7g6QqGHCDJnRbU7DXER00DF7w'
    const { t } = useTranslation();

    const config = {
        headers: { "Authorization": `Bearer ${token}` }
    };

    const [items, setItems] = useState([]);
    const [error, setError] = useState();
    const [estado, setEstado] = useState([]);

    useEffect(() => {
        getPlaya()
        getEstado()
    }, [])

    const getEstado = () => {
        axios.get(api, config)
            .then((response) => {
                const allEstados = response.data.playa.estado;
                setEstado(allEstados);
            })
    }

    const getPlaya = () => {
        axios.get(api, config)
            .then((response) => {
                const allPlayas = response.data.playa;
                setItems(allPlayas);
            })
    }

    return (
        <div className='bodyInfo'>
            <div className="viewHeader">
                <h1>
                    <span>{items.descripcion}</span>
                </h1>
            </div>
            <div className="beachInfo">
                <div className="description">
                    <div className="image">
                        <img src={items.img_url}></img>
                    </div>
                    <div className="beachDescription">
                        <div className="titleDescription">
                        {t("beachInfo.description")}
                        </div>
                        <div>
                            {items.info}
                        </div>
                        <div className="titleEstado">
                        {t("beachInfo.state")}
                        </div>
                        <div>
                            {/* <BeachStatus codnumplaya={params.codnumplaya} /> */}
                            {/* {estado.bandera} */}
                            <div>
                                <b>{t("beachInfo.flag")}:</b> {estado.bandera}
                            </div>
                            <div>
                                <b>{t("beachInfo.jellyfish")}:</b>

                                {estado.medusas == 0
                                    ? t("beachInfo.noJelly")
                                    : t("beachInfo.yesJelly")
                                }
                            </div>
                            <div>
                                <b>{t("beachInfo.temperature")}:</b> {estado.temperatura}
                            </div>
                            <div>
                                <b>{t("beachInfo.temperatureWater")}:</b> {estado.temperatura_agua}
                            </div>
                            <div>
                                <b>{t("beachInfo.rayUV")}:</b> {estado.rayosuv}
                            </div>
                            <div>
                                <b>{t("beachInfo.windSpeed")}:</b> {estado.velocidadviento}
                            </div>
                            <div>
                                <b>{t("beachInfo.windDirection")}:</b> {estado.direccionviento}
                            </div>
                        </div>
                        <div className='titleServicios'>
                        {t("beachInfo.services")}
                        </div>
                        <div>
                        {t("beachInfo.avaliableChairs")}:{items.sillas}
                        </div>
                        <div>
                        {t("beachInfo.flag")}:{items.sombras}
                        </div>
                    </div>
                </div>
                <div className='containerForm'>
                    <div className="map">
                        <Map />
                    </div>
                    <div className='enlacesReserva'>
                        <Link to={`/book/${items.codnumplaya}/silla`}>{t("beachInfo.bookChairs")}</Link>
                        <Link to={`/book/${items.codnumplaya}/sombra`}>{t("beachInfo.bookShadows")}</Link>
                    </div>
                    <div className='encuesta'>
                        <Encuesta />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BeachInfo
