import './App.css';
import Beach from './components/Beach';
import Slider from './components/Slider';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { useEffect, useState } from 'react';

function App() {
  return (
    <div className="App">
      <div className="header">
        <Slider />
        <Navbar />
      </div>
      <div className="body">
        <Beach />
        {/* <BeachInfo /> */}
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
