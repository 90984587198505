import "react-datepicker/dist/react-datepicker.css";
import '../assets/styles/style.css'
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { Link, useParams } from 'react-router-dom';
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import axios from "axios";

function ReservaDiaHoras() {

    const params = useParams()
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const { t } = useTranslation();

    const api = 'https://api.carboneras.notacool.com/api/v1/gestionReservas/reservas/count'
    const apiPost = 'https://api.carboneras.notacool.com/api/v1/gestionReservas/reserva'
    const apiPlaya = `https://api.carboneras.notacool.com/api/v1/gestionPlayas/playa/${params.codnumplaya}`
    const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJVc2VyIERldGFpbHMiLCJpc3MiOiJZT1VSIEFQUExJQ0FUSU9OL1BST0pFQ1QvQ09NUEFOWSBOQU1FIiwiaWF0IjoxNjUzNDAwODcyLCJlbWFpbCI6ImJyYWlzIn0.rTqE0QHdDh2FHjyd1e7g6QqGHCDJnRbU7DXER00DF7w'

    const [startDate, setStartDate] = useState(new Date());
    // const [startDate, setStartDate] = useState()
    const [horaEntradaVar, setHoraEntrada] = useState('');
    const [horaSalidaVar, setHoraSalida] = useState('');
    const [playa, setPlaya] = useState();

    const [plazasReservadas, setPlazasReservadas] = useState('')

    const [playaInfo, setPlayaInfo] = useState({
        codnumplaya: params.codnumplaya
    })

    const [estadoReserva, setEstadoReserva] = useState({
        codnumestado: 1
    })

    const [reservaInfo, setReservaInfo] = useState({
        dniusuario: '',
        emailusuario: '',
        codnumplaya: playaInfo,
        servicio: params.tiporeserva,
        cantidad: 2,
        fecha: format(new Date(), 'dd/MM/yyyy').toString(),
        horaInicioReserva: '',
        horaFinReserva: '',
        estado: estadoReserva
    });

    const { dniusuario, emailusuario, codnumplaya, tipo_servicio, cantidad, dia, horaEntrada, horaSalida } = reservaInfo;


    const bookingHours = [
        { id: 1, hour: '10:00' },
        { id: 2, hour: '10:30' },
        { id: 3, hour: '11:00' },
        { id: 4, hour: '11:30' },
        { id: 5, hour: '12:00' },
        { id: 6, hour: '12:30' },
        { id: 7, hour: '13:00' },
        { id: 8, hour: '13:30' },
        { id: 9, hour: '14:00' },
        { id: 10, hour: '14:30' },
        { id: 11, hour: '15:00' },
        { id: 12, hour: '15:30' },
        { id: 13, hour: '16:00' },
        { id: 14, hour: '16:30' },
        { id: 15, hour: '17:00' },
        { id: 16, hour: '17:30' },
        { id: 17, hour: '18:00' },
        { id: 18, hour: '18:30' },
        { id: 19, hour: '19:00' },
        { id: 20, hour: '19:30' },
    ]

    useEffect(() => {

        axios({
            method: 'get',
            url: api,
            headers: { Authorization: `Bearer ${token}` },
            params: {
                fecha: reservaInfo.fecha,
                servicio: params.tiporeserva,
                idPlaya: params.codnumplaya
            }
        }).then(
            (response) => {
                setPlazasReservadas(response.data)
            }, (error) => {
                console.log(error)
            }
        )
    });

    const comprobarDisponibilidad = async () => {
        // await getPlaya()

        const { data } =
            await axios({
                method: 'get',
                url: apiPlaya,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        const playaInfo = { ...data.playa }

        const horasAReservar = bookingHours.slice(bookingHours.findIndex(e => e.hour == reservaInfo.horaInicioReserva), bookingHours.findIndex(e => e.hour == reservaInfo.horaFinReserva))

        var realizarReserva = true

        const servicio = params.tiporeserva

        for (var i = 0; i < horasAReservar.length; i++) {

            if (servicio === 'silla') {
                const hora = horasAReservar[i].hour
                const sillasDisponibles = playaInfo.sillas - plazasReservadas[hora]

                if (parseInt(cantidad) > sillasDisponibles) {
                    realizarReserva = false
                    alert(t("reserva.errorChairs") + horasAReservar[i].hour + t("reserva.esDe") + sillasDisponibles)
                    break
                }
            } else if (servicio === 'sombra') {
                const hora = horasAReservar[i].hour
                const sombrasDisponibles = playaInfo.sombras - plazasReservadas[hora]

                if (parseInt(cantidad) > sombrasDisponibles) {
                    realizarReserva = false
                    alert(t("reserva.errorChairs") + horasAReservar[i].hour + t("reserva.esDe") + sombrasDisponibles)
                    break
                }
            }

        }

        if (realizarReserva) {
            setButtonDisabled(true)
            axios({
                method: 'post',
                url: apiPost,
                headers: { Authorization: `Bearer ${token}` },
                data: reservaInfo
            }).then(
                (response) => {
                    setButtonDisabled(false)
                    alert(t("reserva.bookingCompleted"))
                }, (error) => {
                    console.log(error)
                    console.log(reservaInfo)
                    alert(t("reserva.errorBooking"))
                })
        }

    }

    const handleSubmit = () => {

        if ((reservaInfo.dniusuario === '') || (reservaInfo.emailusuario === '') || (reservaInfo.fecha === '') || (reservaInfo.horaFinReserva === '') || (reservaInfo.horaInicioReserva === '')) {
            alert(t("reserva.errorBooking2"))
        } else {
            comprobarDisponibilidad();
        }
    }

    const onInputChange = e => {
        setReservaInfo({ ...reservaInfo, [e.target.name]: e.target.value })
    }

    function onChangeDate(value) {
        console.log(format(value, 'dd/MM/yyyy').toString())
        setStartDate(value)
        setReservaInfo({ ...reservaInfo, fecha: format(value, 'dd/MM/yyyy').toString() })
    }

    return (

        <div className='reservaContainer'>
            <div className='reserva'>
                <div className="infoUsuario">
                    <div className="dniuser">
                        <label>
                            {t("reserva.dni")}
                            <span title="Este campo es obligatorio." className="form-required">*</span>
                        </label>
                        <input name='dniusuario' value={dniusuario} onChange={onInputChange} type={"text"} />
                    </div>
                    <div className="mailuser">
                        <label>
                            {t("reserva.email")}
                            <span title="Este campo es obligatorio." className="form-required">*</span>
                        </label>
                        <input name='emailusuario' value={emailusuario} onChange={onInputChange} type={"email"} />
                    </div>
                </div>
                <div className='reservaFechaHora'>
                    <div className='reservaFecha'>
                        <DatePicker
                            name={"dia"}
                            selected={startDate}
                            onChange={onChangeDate}
                            inline
                        />
                    </div>
                    <div className="reservaHoras">
                        <label>{t("reserva.from")}</label>
                        {/* <TimePicker minuteStep={30} name={'horaEntrada'} showSecond={false} defaultValue={''} onChange={onChangeEntrada} /><br /> */}
                        <select name="horaInicioReserva" onChange={onInputChange}>
                            <option>{t("reserva.select")}</option>
                            {bookingHours.map(item => {
                                return (<option key={item.id} value={item.hour}>{item.hour}</option>);
                            })}
                        </select>
                        <label>{t("reserva.to")}</label>
                        {/* <TimePicker minuteStep={30} name={'horaSalida'} showSecond={false} defaultValue={moment()} onChange={onChangeSalida} /> */}
                        <select name="horaFinReserva" onChange={onInputChange}>
                            <option>{t("reserva.select")}</option>
                            {bookingHours.map(item => {
                                return (<option key={item.id} value={item.hour}>{item.hour}</option>);
                            })}
                        </select>
                    </div>
                    <div>
                        <input name={'cantidad'} value={cantidad} type={'number'} min={1} max={10} onChange={onInputChange}></input>
                    </div>
                </div>
                <div className='infoReserva'>
                    <div>
                        <h1>{t("reserva.info")}</h1>
                    </div>
                    <div className='infoDiaReserva'>
                        <label>{t("reserva.day")}</label>
                        <div>{format(startDate, 'dd/MM/yyyy')}</div>
                    </div>
                    <div className='infoHoraReserva'>
                        <label>{t("reserva.schedule")}</label>
                        <div>Desde:{reservaInfo.horaInicioReserva}<br />Hasta:{reservaInfo.horaFinReserva}</div>
                    </div>
                    <div className='numReservas'>
                        <label>{t("reserva.bookingNumber")}:</label>
                        <div>{cantidad}</div>
                    </div>
                </div>

            </div>
            <div>
                <button className='reservaButton' disabled={buttonDisabled} onClick={handleSubmit}>{t("reserva.book")}</button>
            </div>
        </div>

    );

}

export default ReservaDiaHoras;
