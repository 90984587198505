import SimpleImageSlider from "react-simple-image-slider";
import useScript from "../hooks/useScript";
import "../assets/styles/style.css";
import { useTranslation } from 'react-i18next';

const images = [{ url: "/img/slider1.jpg" }, { url: "/img/slider2.jpg" }];
const Slider = (props) => {
  const { t } = useTranslation();
  const script = useScript(
    "https://www.tutiempo.net/widget/eltiempo_tuhgbhdhd88aBG6UMfVDzjzDzWlUMUBFbYEd1ZyIqEz"
  );

  return (
    <div className="container">
      <div className="logo">
        <a href="/" title="Inicio" rel="home">
          <img src="/img/carboneras.png" alt="Inicio" />
        </a>
        <img className="logoJunta" src="/img/junta_logo.png" alt="Logo junta" />
      </div>
      <div className="slider">
        <SimpleImageSlider
          className="slider_content"
          width={"100%"}
          height={420}
          images={images}
          showBullets={true}
          style={{
            overflow: "hidden",
            boxShadow: "0 6px 16px #000",
            margin: "auto",
          }}
          showNavs={true}
        />
      </div>
      <div className="time">
        <div id="TT_tuhgbhdhd88aBG6UMfVDzjzDzWlUMUBFbYEd1ZyIqEz">
            Pronóstico de Tutiempo.net
        </div>
        {script}
      </div>
    </div>
  );
};

export default Slider;
