import React from "react";
import Flag from "react-world-flags";
import { useTranslation } from "react-i18next";

export function LanguagePicker() {
  const { i18n } = useTranslation("translation");

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="langBox">
      <button className={i18n.language === 'es' ? 'langSelected' : '' } onClick={()=>changeLang("es")}>
        <Flag code={"es"} />
      </button>
      <button className={i18n.language === 'en' ? 'langSelected' : '' } onClick={()=>changeLang("en")}>
        <Flag code={"gb"} />
      </button>
      <button className={i18n.language === 'fr' ? 'langSelected' : '' } onClick={()=>changeLang("fr")}>
        <Flag code={"fr"} />
      </button>
    </div>
  );
}
