import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App';
import Beaches from "./routes/beaches";
import BeachInfo from './routes/beachInfo';
import Incidents from './routes/incidents';
import Reserva from './routes/reservas';
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/beaches" element={<Beaches />} />
      <Route path='/beachInfo' element={<BeachInfo/>}/>
      <Route path='/beachInfo/:codnumplaya' element={<BeachInfo />}/>
      <Route path='/incidents' element={<Incidents />} />
      <Route path='/book/:codnumplaya/:tiporeserva' element={<Reserva />}/>
    </Routes>
  </BrowserRouter>);
