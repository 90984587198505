import { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function Sugerencias() {

    const [sugerenciaInfo, setSugerenciaInfo] = useState({
        sugerencia: ''
    });
    const { t } = useTranslation();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const apiPost = 'https://api.carboneras.notacool.com/api/v1/gestionSugerencias/sugerencia'
    const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJVc2VyIERldGFpbHMiLCJpc3MiOiJZT1VSIEFQUExJQ0FUSU9OL1BST0pFQ1QvQ09NUEFOWSBOQU1FIiwiaWF0IjoxNjUzNDAwODcyLCJlbWFpbCI6ImJyYWlzIn0.rTqE0QHdDh2FHjyd1e7g6QqGHCDJnRbU7DXER00DF7w'

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const {sugerencia} = sugerenciaInfo

    const handleSubmit = () => {

        if (sugerencia === '') {
            console.log(JSON.stringify(sugerencia))
            alert(t("suggestions.empty"))
        } else {
            setButtonDisabled(true)
            axios({
                method: 'post',
                url: apiPost,
                headers: { Authorization: `Bearer ${token}` },
                data: sugerenciaInfo
            }).then(
                (response) => {
                    setButtonDisabled(false)
                    alert(t("suggestions.send"))
                }, (error) => {
                    console.log(error)
                    alert(t("suggestions.error"))
                }
            )

        }

    }

    const onInputChange = e => {
        setSugerenciaInfo({...sugerenciaInfo, sugerencia: e.target.value})
    }

    return (
        <div>
            <textarea name="sugerencia" value={sugerencia} onChange={onInputChange}></textarea><br></br>
            <button onClick={handleSubmit} disabled={buttonDisabled} className="form-submit" value="Enviar" name="op" id="edit-submit" >{t("suggestions.sendButton")} </button>
        </div>
    )
}

export default Sugerencias;
