import Navbar from "../components/Navbar";
import Slider from "../components/Slider";
import BeachInfo from "../components/BeachInfo";
import Footer from "../components/Footer";

export default function Info() {
  return (
    <main>
      <div className="App">
        <Slider/>
        <Navbar/>
        <BeachInfo/>
        <Footer/>
      </div>
    </main>
  );
}