import IncidentsForm from "../components/IncidentsForm";
import Navbar from "../components/Navbar";
import Slider from "../components/Slider";
import Footer from "../components/Footer";

export default function Incidents() {
    return (
      <main>
        <div className="App">
            <Slider />
            <Navbar />
            <IncidentsForm/>
            <Footer/>
        </div>
      </main>
    );
  }