import "../assets/styles/style.css";
import { Link } from "react-router-dom";
import { LanguagePicker } from "./LanguagePicker";
import { useTranslation } from 'react-i18next';

function Navbar() {
  const { t } = useTranslation();

  return (
    <nav className="navbar" class="navbar navbar-dark bg-primary">
      <Link className="link" id="link1" to="/beaches">
      {t("navabr.beaches")}
      </Link>
      <Link className="link" to="/incidents">
      {t("navabr.incidents")}
      </Link>
      <LanguagePicker />
    </nav>
  );
}

export default Navbar;
