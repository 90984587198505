import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Slider from "../components/Slider";
import Reserva from "../components/Reserva";

export default function Reservas() {

    return (
        <main>
            <div className="App">
                <Slider />
                <Navbar />
                <Reserva />
                <Footer />
            </div>
        </main>
    );

}