import '../assets/styles/style.css'
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import axios from 'axios';
import Sugerencias from './Sugerencias';
import { useTranslation } from 'react-i18next';

function Beach() {

    const api = 'https://api.carboneras.notacool.com/api/v1/gestionPlayas/playas/list'
    const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJVc2VyIERldGFpbHMiLCJpc3MiOiJZT1VSIEFQUExJQ0FUSU9OL1BST0pFQ1QvQ09NUEFOWSBOQU1FIiwiaWF0IjoxNjUzNDAwODcyLCJlbWFpbCI6ImJyYWlzIn0.rTqE0QHdDh2FHjyd1e7g6QqGHCDJnRbU7DXER00DF7w'
    const { t } = useTranslation();

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const [items, setItems] = useState([]);
    const [sugerencia, setSugerencia] = useState('');
    const [error, setError] = useState();

    useEffect(() => {
        getPlayas();
    }, [])

    const getPlayas = () => {
        axios.get(api, config)
            .then((response) => {
                const allPlayas = response.data.playas;
                setItems(allPlayas);
            })
    }

    return (
        <div className="body">
            <div className="viewHeader">
                <h1>
                    <span>{t("beach.beaches")}</span>
                </h1>
            </div>
            <div className='beaches'>
                {items && items.map(item => (
                    <div className="beach">
                        <div className="imageContainer">
                            <img className="image" src={item.img_url} />
                        </div>
                        <div className="info">
                            <ul>
                                <li>
                                    <h2 className='beachName'>
                                        <nav>
                                            <Link className="beachLink" to={`/beachInfo/${item.codnumplaya}`}>{item.descripcion}</Link>
                                        </nav>
                                    </h2>
                                </li>
                                <li>
                                    <div>
                                        {console.log(item.estado.bandera)}
                                    </div>
                                </li>
                                <li className="links">
                                    <a id="map" href={item.mapa_url}>{t("beach.map")}</a>
                                    <nav>
                                        <Link id='infoLink' to={`/beachInfo/${item.codnumplaya}`}>{t("beach.readMore")}</Link>
                                    </nav>
                                </li>
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
            <div className="pregunta">
                {t("beach.sugerencias")}
            </div>
            <div>
                <Sugerencias/>
            </div>
        </div>
    )
}

export default Beach;
