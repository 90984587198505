import { Component } from "react";
import '../assets/styles/style.css'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { parse, set } from "date-fns";
import { useTranslation } from 'react-i18next';


function Encuesta() {

    const params = useParams()

    const [buttonDisabled, setButtonDisabled] = useState(false);

    const api = 'https://api.carboneras.notacool.com/api/v1/gestionEncuestas/preguntas/list'
    const apiPost = 'https://api.carboneras.notacool.com/api/v1/gestionEncuestas/respuesta'
    const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJVc2VyIERldGFpbHMiLCJpc3MiOiJZT1VSIEFQUExJQ0FUSU9OL1BST0pFQ1QvQ09NUEFOWSBOQU1FIiwiaWF0IjoxNjUzNDAwODcyLCJlbWFpbCI6ImJyYWlzIn0.rTqE0QHdDh2FHjyd1e7g6QqGHCDJnRbU7DXER00DF7w'
    const [items, setItems] = useState([]);
    const { t } = useTranslation();

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const getPreguntas = () => {
        axios.get(api, config)
            .then((response) => {
                const allPreguntas = response.data.preguntas;
                setItems(allPreguntas);
            })
    }

    useEffect(() => {
        getPreguntas()
        setPlaya({ ...playa, codnumplaya: parseInt(params.codnumplaya) })
        console.log(playa)
        // setFormInfo({ ...formInfo, codnumplaya: playa })
    }, [])

    const [preguntaInfo, setPreguntaInfo] = useState({
        codnumpreg: 0
    })
    const [playa, setPlaya] = useState({
        codnumplaya: parseInt(params.codnumplaya)
    })
    // const { codnumpreg, codnumplaya, respuesta } = formInfo;
    const [respuestas, setRespuestas] = useState([])
    // let respuestas = []

    // const onInputChange = e => {
    //     setPreguntaInfo({ ...preguntaInfo, codnumpreg: parseInt(e.target.id) })
    //     setFormInfo({ ...formInfo, pregunta: preguntaInfo })
    //     setFormInfo({ ...formInfo, respuesta: e.target.value })

    //     respuestas = [ ...respuestas, formInfo ]
    //     console.log(respuestas)
    // }

    // const [respuestas, setRespuestas] = useState([])

    // const onInputChange = e => {
    //     console.log(parseInt("id:" + parseInt(e.target.id)))
    //     setPreguntaInfo({ ...preguntaInfo, codnumpreg: parseInt(e.target.id) })
    //     console.log(preguntaInfo)
    //     // setFormInfo({ ...formInfo, codnumpreg: preguntaInfo })
    //     // setFormInfo({ ...formInfo, respuesta: e.target.value })
    //     setFormInfo({...formInfo, codnumpreg: preguntaInfo, respuesta: e.target.value})
    //     respuestas.push(formInfo)
    //     console.log(formInfo.codnumplaya)
    //     console.log(respuestas)
    //     // setFormInfo({ ...formInfo, [e.target.name]: e.target.value })
    // }

    const onInputChange = e => {

        for (var i = 0; i < respuestas.length; i++) {
            if (respuestas[i].codnumpreg.codnumpreg === parseInt(e.target.id)) {
                respuestas.splice(i, 1)
            }

        }
        const formInfo = {codnumpreg:{codnumpreg:undefined}, codnumplaya: {codnumplaya:undefined}}

        formInfo.codnumpreg.codnumpreg = parseInt(e.target.id)
        formInfo.codnumplaya.codnumplaya = parseInt(params.codnumplaya)
        formInfo.respuesta = e.target.value

        // respuestas = [ ...respuestas, formInfo ]
        setRespuestas((oldRespuestas)=>{
            const newRespuestas = [...oldRespuestas]
            newRespuestas.push(formInfo)
            return newRespuestas
        })
        console.log(`form-->${formInfo}`)
        console.log(respuestas)
    }

    const handleSubmit = () => {

        // console.log(respuestas.length)

        for (var i = 0; i < respuestas.length; i++) {

                console.log(respuestas[i])
                console.log("items.length" + items.length)
                axios({
                    method: 'post',
                    url: apiPost,
                    headers: { Authorization: `Bearer ${token}` },
                    data: respuestas[i]
                }).then(
                    (response) => {
                        setButtonDisabled(false)
                    }, (error) => {
                        console.log(error)
                        console.log(respuestas[i])
                        alert(t("survey.error1"))
                    }
                )
                setButtonDisabled(true)

        }
        alert(t("survey.send"))


    }

    return (
        <div>
            <div className="comienzoEncuesta">
                Encuesta de satisfacción
            </div>
            <div className="satisfactionContent">
                <div className="pregunta">
                {t("survey.opinion")}<span title="Este campo es obligatorio." className="form-required">*</span>
                </div>
                <div>
                    <table>
                        <tr>
                            <td></td>
                            <td>{t("survey.type1")}</td>
                            <td>{t("survey.type2")}</td>
                            <td>{t("survey.type3")}</td>
                            <td>{t("survey.type4")}</td>
                            <td>{t("survey.type5")}</td>
                        </tr>
                        {items && items.map(item => (
                            <tr>
                                <td className="columna1">{item.pregunta}</td>
                                <td className="option">
                                    <input type="radio" id={item.codnumpreg} name={item.pregunta} value={"Muy mala"} onChange={onInputChange}></input>
                                </td>
                                <td className="option">
                                    <input type="radio" id={item.codnumpreg} name={item.pregunta} value={"Mala"} onChange={onInputChange}></input>
                                </td>
                                <td className="option">
                                    <input type="radio" id={item.codnumpreg} name={item.pregunta} value={"Normal"} onChange={onInputChange}></input>
                                </td>
                                <td className="option">
                                    <input type="radio" id={item.codnumpreg} name={item.pregunta} value={"Buena"} onChange={onInputChange}></input>
                                </td>
                                <td className="option">
                                    <input type="radio" id={item.codnumpreg} name={item.pregunta} value={"Muy buena"} onChange={onInputChange}></input>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>

                <div>
                    <button onClick={handleSubmit} className="form-submit" value="Enviar" name="op" id="edit-submit" >{t("suggestions.sendButton")} </button>
                </div>
            </div>
        </div>
    )
}

export default Encuesta;
