import '../assets/styles/style.css'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next';

function IncidentsForm() {

    const api = 'https://api.carboneras.notacool.com/api/v1/gestionPlayas/playas/list'
    const apiPost = 'https://api.carboneras.notacool.com/api/v1/gestionIncidencias/incidencia'
    const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJVc2VyIERldGFpbHMiLCJpc3MiOiJZT1VSIEFQUExJQ0FUSU9OL1BST0pFQ1QvQ09NUEFOWSBOQU1FIiwiaWF0IjoxNjUzNDAwODcyLCJlbWFpbCI6ImJyYWlzIn0.rTqE0QHdDh2FHjyd1e7g6QqGHCDJnRbU7DXER00DF7w'
    const { t } = useTranslation();

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const [items, setItems] = useState([]);
    const [error, setError] = useState();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    var today = format(new Date(), 'dd/MM/yyyy')

    const [incidentInfo, setIncidentInfo] = useState({
        dniusuario: '',
        mailusuario: '',
        playa: '',
        descripcion: '',
        fechaemision: today
    });

    const onInputChange = e => {
        if (e.target.name === 'playa'){
            // setPlayaInfo({ ...playaInfo, codnumplaya: e.target.value })
                const playaInfo = {codnumplaya: parseInt(e.target.value)}
            setIncidentInfo({
                ...incidentInfo, [e.target.name]: playaInfo
            })
        } else {
            setIncidentInfo({ ...incidentInfo, [e.target.name]: e.target.value })
        }
    }

    const { dniusuario, mailusuario, playa, descripcion, fechaemision } = incidentInfo;
    // const {codnumplaya} = playaInfo;

    const handleSubmit = () => {

        if (incidentInfo.dniusuario === '' || incidentInfo.mailusuario === '' || incidentInfo.playa === '' || incidentInfo.descripcion === '') {
            console.log(JSON.stringify(incidentInfo))
            alert(t("incidentForm.emptyField"))
        } else {
            setButtonDisabled(true)
            console.log(`Incidencia-->${incidentInfo}`)
            console.log(incidentInfo)
            axios({
                method: 'post',
                url: apiPost,
                headers: { Authorization: `Bearer ${token}` },
                data: incidentInfo
            }).then(
                (response) => {
                    setButtonDisabled(false)
                    alert(t("incidentForm.notified"))
                }, (error) => {
                    console.log(error)
                    alert(t("incidentForm.failed"))
                }
            )

        }

    }

    const getPlayas = () => {
        axios.get(api, config)
            .then((response) => {
                const allPlayas = response.data.playas;
                setItems(allPlayas);
            })
    }

    useEffect(() => {
        getPlayas()
    }, [])

    return (
        <div className="body">
            <div className="viewHeader">
                <h1>
                    <span>{t("navabr.incidents")}</span>
                </h1>
            </div>
            <form className="incidencias" >
                <div>
                    <label>
                    {t("incidentForm.dni")}
                        <span title={t("incidentForm.mandarotyField")} className="form-required">*</span>
                    </label>
                    <input name='dniusuario' value={dniusuario} onChange={onInputChange} type={"text"} />
                </div>
                <div>
                    <label>
                    {t("incidentForm.email")}
                        <span title={t("incidentForm.mandarotyField")} className="form-required">*</span>
                    </label>
                    <input name='mailusuario' value={mailusuario} onChange={onInputChange} type={"email"} />
                </div>
                <div>
                    <label>
                    {t("incidentForm.beach")}
                        <span title={t("incidentForm.mandarotyField")} className="form-required">*</span>
                    </label>
                    <select name='playa' onChange={onInputChange}>

                        <option>{t("reserva.selectBeach")}</option>
                        {items && items.map(item => (
                            <option value={item.codnumplaya}>{item.descripcion}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>
                    {t("incidentForm.description")}
                        <span title={t("incidentForm.mandarotyField")} className="form-required">*</span>
                    </label>
                    <textarea name='descripcion' value={descripcion} onChange={onInputChange} />
                </div>
                <div>

                    <button onClick={handleSubmit} disabled={buttonDisabled} className="form-submit" value="Enviar" name="op" id="edit-submit" >{t("suggestions.sendButton")} </button>
                </div>
            </form>
        </div>
    );
}

export default IncidentsForm
