import App from "../App";
import Beach from "../components/Beach";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Slider from "../components/Slider";

export default function Beaches() {
  return (
    <main>
      <div className="App">
        <Slider />
        <Navbar />
        <Beach />
        <Footer/>
      </div>
    </main>
  );
}