import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { FacebookProvider, Page } from 'react-facebook';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();

    return (
        <div className='footerContainer'>
            <div className='footer'>
                <div className='footerContent'>
                    <div className='imgFooter'>
                        <img src='/img/mlogo_footer.png'></img>
                    </div>
                    <div className='infoFooter'>
                        
                    </div>
                    <div className='twitter'>
                        <p>{t("footer.twitterActivity")}</p>
                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="Ayto_Carboneras"
                            options={{ height: 320 }}
                        />
                    </div>
                    <div className='facebook'>
                        <p>{t("footer.facebookActivity")}</p>
                        <FacebookProvider appId="320856026800031">
                            <Page href="https://www.facebook.com/ayuntamientocarboneras/" tabs="timeline" height="320" />
                        </FacebookProvider>
                    </div>
                </div>
            </div>
            <div className='subfooter'>
                © 2022 | <strong>{t("footer.council")}</strong> | Plza. Castillo, 1. 04140 Carboneras, Almería | Tel. Centralita 950 454238 Fax: 950 130708
                <p>
                    <a href='http://www.ayuntamientocarboneras.es/politica-de-privacidad-sus-datos-seguros'>{t("footer.privacy")}</a>
                    &nbsp;| <a href='http://www.ayuntamientocarboneras.es/compromiso-con-la-proteccion-de-datos-personales'>{t("footer.personalData")}</a>
                    &nbsp;| <a href='http://www.ayuntamientocarboneras.es/politica-de-cookies'>{t("footer.cookies")}</a>
                </p>
            </div>

        </div>
    );
}

export default Footer;